import React, {useState} from "react";
import { Link } from "react-router-dom";
import {Form, InputGroup, Button, Col} from "react-bootstrap"
import {AiFillEyeInvisible, AiFillEye} from "react-icons/ai"
import {FcGoogle} from "react-icons/fc"
import {FaFacebook, FaTwitter, FaApple} from "react-icons/fa"
import ProfileImgUser from "../../assets/images/others/profileUser.png";

function CreateAccountUser() {
    const [password, setPassword] = useState(false);
    const [cPassword, setCpassword] = useState(false);
    const RenderLabel = () => {
        return (
            <>
                I accept the <Link to={"/terms-of-service"}>Terms of service</Link> and the<br/> <Link to={"/privacy-policy"}>Privacy policy</Link>
            </>
        )
    }
    return (
        <div className={"loginForm "}>
            <h1>Create account</h1>
            <p>Quam pulvinar nullam quam nunc.</p>
            <div className={"m-t-40"}></div>
            <div className={"mx-auto text-center"}>
                <img className={"img-fluid imgProfile"} src={ProfileImgUser} alt={"Profile Img"} title={"Profile Img"} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src=ProfileImgUser;
                }}/>
                <Form.Group controlId="profileImg" className="LabelFile m-t-10">
                    <Form.Label>Change image</Form.Label>
                    <Form.Control type="file" />
                </Form.Group>
            </div>

            <div className={"filler m-t-30"}></div>
            <form className={"m-t-40"} autoComplete={"off"}>
                <h3>Account Info</h3>
                <div className={"mb13"}>
                    <Form.Control type="text" autoComplete={"off"} placeholder="First Name" className={"formNameF"} />
                </div>

                <div className={"mb13"}>
                    <Form.Control type="text" autoComplete={"off"} placeholder="Last Name" className={"formNameL"} />
                </div>

                <div className={"mb13"}>
                    <Form.Control type="email" autoComplete={"off"} placeholder="Email address" className={"formemail"} />
                </div>

                <div className={"mb13"}>
                    <Form.Control type="text" autoComplete={"off"} placeholder="Username" className={"formUsername"} />
                </div>

                <div className={"mb13"}>
                    <Form.Control type="date" autoComplete={"off"} placeholder="Date of birth" className={"formDob"} />
                </div>

                <div className={"mb13"}>
                    <Form.Select id={"formLocation"}>
                        <option>Location</option>
                    </Form.Select>
                </div>



                <div className={"filler m-t-30"}></div>
                <h3>Password</h3>
                <div className={"mb13"}>
                    <InputGroup className={"passwordGroup"}>
                        <Form.Control
                            type={password ? "text" : "password"} placeholder="Password"
                            aria-label="password"
                            autoComplete={"off"}
                            aria-describedby="inputGroup-sizing-sm"
                            className={"mb-0"}
                        />
                        <Button variant="outline-secondary" id="button-addon1" onClick={()=>setPassword(!password)}>
                            {password ? <AiFillEyeInvisible /> : <AiFillEye/>}
                        </Button>
                    </InputGroup>
                </div>
                <div className={"mb13"}>
                    <InputGroup className={"passwordGroup"}>
                        <Form.Control
                            type={cPassword ? "text" : "password"} placeholder="Repeat password"
                            aria-label="password"
                            autoComplete={"off"}
                            aria-describedby="inputGroup-sizing-sm"
                            className={"mb-0"}
                        />
                        <Button variant="outline-secondary" id="button-addon2" onClick={()=>setCpassword(!cPassword)}>
                            {cPassword ? <AiFillEyeInvisible /> : <AiFillEye/>}
                        </Button>
                    </InputGroup>
                </div>


                <ul className={"list-login list-unstyled"}>
                    <li className={"list-item "}>
                        <Form.Check
                            type={"checkbox"}
                            id={"consentPolicy"}
                            label={<RenderLabel />}
                            className={"formCheckbox"}
                        />
                    </li>
                    <li className={"list-item m-t-20"}>
                        <Form.Check
                            type={"checkbox"}
                            id={"consentAge"}
                            label={"I confirm I’m over 13 years old"}
                            className={"formCheckbox"}
                        />
                    </li>
                </ul>
                <Button type={"submit"} className={"w-100 btn-solid"}>
                    Create account
                </Button>
            </form>
            <div className={"filler m-t-30"}></div>
            <p className={"text-center p-account"}>Already have an account? <Link to={"/"} className={"linkNav"}>Sign in</Link></p>
            <div className={"filler m-b-30"}></div>
        </div>
    );
}

export default CreateAccountUser;
