import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import LogoImg from '../../../assets/images/logo/logo.svg';
import {NavLink, Link} from "react-router-dom";

const NavLinks = [{
        type: "link",
        url: "/",
        urlText: "Home",
        disabled: false
    },
    {
        type: "link",
        url: "/our-team",
        urlText: "Our Team",
        disabled: false
    },
    {
        type: "link",
        url: "/why-ourchat",
        urlText: "Why OurChat",
        disabled: false
    },
    {
        type: "link",
        url: "/contact",
        urlText: "Contact Us",
        disabled: false
    },
    {
        type: "dropdown",
        url: "/",
        urlText: "Services",
        dropdownItems: [{
                url: "/service-01",
                urlText: "Service 01",
                disabled: false
            },
            {
                url: "/service-02",
                urlText: "Service 02",
                disabled: true
        }],
    }];

const NavBtns = [{
        btnType: "outline",
        btnText: "Login",
        btnUrl: "/login",
    },
    {
        btnType: "solid",
        btnText: "Sign up",
        btnUrl: "/sign-up",
    }];

function Navigation() {
    return (
        <Navbar bg="light" expand="lg" fixed="top" className={"ourChatNav"}>
            <Container fluid>
                <Navbar.Brand href="/">
                    <img src={LogoImg} alt={"Our Chat"} title={"Our Chat"} className={"img-fluid"} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        // style={{ maxHeight: '100px' }}
                        // navbarScroll
                    >

                        {
                            NavLinks.map((links, index)=>{
                                /*if navigation link type is link then links gets listed*/
                                if(links.type === "link"){
                                    return(
                                        <NavLink key={index} to={links.url} className={"nav-link"} disable={links.disabled ? "true" : "false"}>
                                            {links.urlText}
                                        </NavLink>
                                    )
                                }
                                /*if navigation link type is dropdown then dropdown gets listed*/
                                if(links.type === "dropdown"){
                                    return(
                                        <NavDropdown key={index} title={links.urlText} id={index}>
                                            {
                                                links.dropdownItems.map((dropItems, index2)=>{
                                                    return(
                                                        <NavLink key={index2} to={dropItems.url} className={"dropdown-item"}>
                                                            {dropItems.urlText}
                                                        </NavLink>
                                                    )
                                                })
                                            }
                                        </NavDropdown>
                                    )
                                }
                            })
                        }
                    </Nav>
                    <div className="d-flex">
                        <Link to={"/"} className={"btn-rounded-outline"}>
                            Login
                        </Link>
                        <Link to={"/sign-up"} className={"btn-rounded-outline active"}>
                            Sign up
                        </Link>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;