import React, {useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import {Form, InputGroup, Button} from "react-bootstrap"
import {AiFillEyeInvisible, AiFillEye} from "react-icons/ai"
import {FcGoogle} from "react-icons/fc"
import {FaFacebook, FaTwitter, FaApple} from "react-icons/fa"
import { useForm } from "react-hook-form";
import {VALIDATIONS, VALIDATIONS_TEXT, Patterns} from "../utils/constants";
import { ToastContainer, toast } from 'react-toastify';
import {SuccessNotificationIcon} from "../components/SvgComponents";


function SignIn() {
    const navigate = useNavigate();
    const [password, setPassword] = useState(false);

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const onSubmit = (data) => {
        console.log(data);
        toast.success("Signed In Successfully", {
            icon: <SuccessNotificationIcon />,
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setTimeout(() => {
            navigate("/myfeed");
        }, 5000);

    }

  return (
    <div className={"loginForm"}>
      <h1>Login</h1>
      <p>Quam pulvinar nullam quam nunc.</p>
        <div className={"filler m-t-30"}></div>
        <form className={"m-t-40"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
            <div className={"mb13"}>
                <Form.Control type="email" autoComplete={"off"} placeholder="username or email" className={"formEmail"}
                              {...register("email",
                                  {
                                      maxLength: {
                                          value: VALIDATIONS.EMAIL,
                                          message: VALIDATIONS_TEXT.EMAIL_MAX
                                      },
                                      pattern: {
                                          value: Patterns.Email,
                                          message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                      },
                                      required: {
                                          value: true,
                                          message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                      },
                                  })
                              }
                />
                {errors.email &&
                <span className={"validation-error"} role="alert">{errors.email.message}</span>}
            </div>
            <div className={"mb13"}>
                <InputGroup className={"passwordGroup"}>
                    <Form.Control
                        type={password ? "text" : "password"} placeholder="Password"
                        aria-label="password"
                        autoComplete={"off"}
                        aria-describedby="inputGroup-sizing-sm"
                        className={"mb-0"}
                        {...register("password",
                            {
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                },
                            })
                        }
                    />
                    <Button variant="outline-secondary" id="button-addon1" onClick={()=>setPassword(!password)}>
                        {password ? <AiFillEyeInvisible /> : <AiFillEye/>}
                    </Button>
                </InputGroup>
                {errors.password &&
                <span className={"validation-error"} role="alert">{errors.password.message}</span>}
            </div>


            <ul className={"list-login list-inline d-flex justify-content-between align-items-center"}>
                <li className={"list-inline-item"}>
                    <Form.Check
                        type={"checkbox"}
                        id={"rememberMe"}
                        label={"Remember Me"}
                        className={"formCheckbox"}
                    />
                </li>
                <li className={"list-inline-item"}>
                    <Link to={"/forgot-password"} className={"linkNav"}>
                        Forgot Password
                    </Link>
                </li>
            </ul>
            <Button type={"submit"} className={"w-100 btn-solid"}>
                Sign In
            </Button>
        </form>
        <Button type={"button"} className={"w-100 btn-solid active"}>
            <FcGoogle /> Sign in with Google
        </Button>
        <Button type={"button"} className={"w-100 btn-solid active"}>
            <FaFacebook/> Sign in with Facebook
        </Button>
        <Button type={"button"} className={"w-100 btn-solid active"}>
            <FaApple/> Sign in with Apple
        </Button>
        <Button type={"button"} className={"w-100 btn-solid active"}>
            <FaTwitter /> Sign in with Twitter
        </Button>
        <div className={"filler m-t-30"}></div>
        <p className={"text-center p-account"}>Don’t have an account? <Link to={"sign-up"} className={"linkNav"}>Sign up</Link></p>
        <hr className={"hr-short"}/>
        <p className={"text-center p-account boxed-account"}>Are you a professional? <Link to={"/create-account-professional"} className={"linkNav"}>Sign up here</Link></p>
    </div>
  );
}

export default SignIn;
