import React, {useState} from 'react';
import {Button} from "react-bootstrap";
import OtpInput from "react-otp-input";
import {InstagramIcon, MessengerIcon, SuccessNotificationIcon, TwitterIcon} from "../components/SvgComponents";
import { useForm } from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

/*
*
*  Box Invite list and Component
*
* */
const InviteList = [{
        platform: "Instagram",
        icon: <InstagramIcon/>,
        text: "Send via Instagram",
        para: "Curabitur id ex et ex pharetra mollis. Duis placerat, augue.",
    },
    {
        platform: "Twitter",
        icon: <TwitterIcon/>,
        text: "Send via Twitter",
        para: "Curabitur id ex et ex pharetra mollis. Duis placerat, augue.",
    },
    {
        platform: "Messenger",
        icon: <MessengerIcon/>,
        text: "Send via Messenger",
        para: "Curabitur id ex et ex pharetra mollis. Duis placerat, augue.",
    }];
const BoxInvite = ({icon, text, para, ...restProps}) => {
    return(
        <div className={"boxSendCode"} {...restProps}>
            <div className={"Icon"}>
                {icon}
            </div>
            <div className={"Text"}>
                <span>{text}</span>
                <span>{para}</span>
            </div>
        </div>
    );
}

function AccountVerification() {
    const navigate = useNavigate();
    /*otp code state*/
    const [otpCode, setOtpCode] = useState();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { register, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: "onChange"
    });

    const handleOtpChange = (otp) => {
        setOtpCode(otp);
        if (otpCode.length === 0) {
            setError(true)
            setErrorMessage("Please Enter OTP code")
        }
        else if (otpCode.length > 0 && otpCode.length < 3) {
            setError(true)
            setErrorMessage("OTP code is incomplete")
        }
        else {
            setError(false)
        }
        console.log(otpCode);
    }

    const onSubmit = (data) => {
        if (otpCode.length === 0) {
            setError(true)
            setErrorMessage("Please Enter OTP code")
        }
        else if (otpCode.length > 0 && otpCode.length < 3) {
            setError(true)
            setErrorMessage("OTP code is incomplete")
        }
        else {
            setError(false)
            // success("OTP verified")
            console.log(data);
            toast.success("Account verification success", {
                icon: <SuccessNotificationIcon />,
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setTimeout(() => {
                navigate("/success-verified-profile")
            }, 5000)
        }
    }
    return (
        <div className={"loginForm "}>
            <h1>Verify your account</h1>
            <p>Urna nunc id cursus metus aliquam eleifend<br/> mi. Duis ultricies lacus sed turpis tincidunt id<br/> aliquet risus.</p>
            <div className={"filler m-t-30"}></div>
            <form className={"m-t-40"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                <div className={"OtpInput-container m-b-40"}>
                    <OtpInput
                        value={otpCode}
                        onChange={(otp)=>handleOtpChange(otp)}
                        numInputs={4}
                        isInputNum={true}
                        placeholder={"4239"}
                        className={"OtpInput"}
                        hasErrored={true}
                        focusStyle="focus"
                    />
                    {error && <span className={"validation-error"} role="alert">{errorMessage}</span>}
                </div>

                {/* mapped component for sending invitational code to user */}
                {
                    InviteList.map((inviteBtns, index)=>{
                        return(
                            <BoxInvite key={index} icon={inviteBtns.icon} text={inviteBtns.text} para={inviteBtns.para} onClick={()=>console.log("invite sent to: ", inviteBtns.platform)} />
                        )
                    })
                }

                <Button type={"submit"} className={"w-100 btn-solid m-t-30"}>
                    Continue
                </Button>
            </form>
            <div className={"filler m-b-30"}></div>
        </div>
    );
}

export default AccountVerification;