import React from 'react'
import {Container, Row, Col} from "react-bootstrap"
import {Link} from "react-router-dom"
import profile01 from "../../assets/images/others/feed/feed1.png"
import profile02 from "../../assets/images/others/feed/feed2.png"
import profile03 from "../../assets/images/others/feed/feed3.png"
import profile04 from "../../assets/images/others/feed/feed4.png"
import profile05 from "../../assets/images/others/feed/feed5.png"
import profile06 from "../../assets/images/others/feed/feed6.png"
import profile07 from "../../assets/images/others/feed/feed7.png"
import profile08 from "../../assets/images/others/feed/feed8.png"
import profile09 from "../../assets/images/others/feed/feed9.png"
import profile10 from "../../assets/images/others/feed/feed10.png"
import profile11 from "../../assets/images/others/feed/feed11.png"
import profile12 from "../../assets/images/others/feed/feed12.png"
import {Star} from "../components/SvgComponents";

const feed = [{
        category: "featured",
        url: "/user_profile/1",
        img: profile01,
        username: "BracketsLIVE",
        tag: "Vlogger",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "featured",
        url: "/user_profile/1",
        img: profile02,
        username: "Bryce Grays",
        tag: "Athlete",
        tokenCount: "20",
        isCharityMember: false
    },
    {
        category: "featured",
        url: "/user_profile/1",
        img: profile03,
        username: "David Johnson",
        tag: "Athlete",
        tokenCount: "125",
        isCharityMember: false
    },
    {
        category: "featured",
        url: "/user_profile/1",
        img: profile01,
        username: "BracketsLIVE",
        tag: "Athlete",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "featured",
        url: "/user_profile/1",
        img: profile02,
        username: "Bryce Grays",
        tag: "Athlete",
        tokenCount: "20",
        isCharityMember: false
    },
    {
        category: "featured",
        url: "/user_profile/1",
        img: profile03,
        username: "David Johnson",
        tag: "Athlete",
        tokenCount: "125",
        isCharityMember: false
    },
    {
        category: "trending",
        url: "/user_profile/1",
        img: profile04,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "trending",
        url: "/user_profile/1",
        img: profile05,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "trending",
        url: "/user_profile/1",
        img: profile06,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "trending",
        url: "/user_profile/1",
        img: profile04,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "trending",
        url: "/user_profile/1",
        img: profile05,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "trending",
        url: "/user_profile/1",
        img: profile06,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "athletes",
        url: "/user_profile/1",
        img: profile07,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "athletes",
        url: "/user_profile/1",
        img: profile08,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "athletes",
        url: "/user_profile/1",
        img: profile09,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "athletes",
        url: "/user_profile/1",
        img: profile07,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "athletes",
        url: "/user_profile/1",
        img: profile08,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "athletes",
        url: "/user_profile/1",
        img: profile09,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "musicians",
        url: "/user_profile/1",
        img: profile10,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "musicians",
        url: "/user_profile/1",
        img: profile11,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "musicians",
        url: "/user_profile/1",
        img: profile12,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "musicians",
        url: "/user_profile/1",
        img: profile10,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "musicians",
        url: "/user_profile/1",
        img: profile11,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
    {
        category: "musicians",
        url: "/user_profile/1",
        img: profile12,
        username: "CoopStreams",
        tag: "Streamer",
        tokenCount: "85",
        isCharityMember: false
    },
]

const ProfileCard = ({url, img, isCharityMember, username, tag, tokenCount}) => {
    return(
        <Col xs={6} sm={6} md={4} lg={2} xl={2}>
            <Link to={url} className={`boxProfessional ${isCharityMember ? "charityMember" : ''}`}>
                <div>
                    <div className={"imgContainer"}>
                        <img className={"img-fluid imgProfile"} src={img} alt={"profile"} title={"profile"}/>
                    </div>
                    <h4>{username}</h4>
                    <p className={"tag"}>{tag}</p>
                    <p className={"tokenCount"}><Star /> {tokenCount} tokens</p>
                </div>
            </Link>
        </Col>
    )
}

function MyFeed() {
    return (
        <div className={"mainDashboard"}>
            <div className={"feedDisplay"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row className={"m-b-20"}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <h1>My feed</h1>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>

                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <h3 className={"m-b-20"}>Featured Professionals</h3>
                                </Col>
                                {
                                    feed.map((profiles, index)=>{
                                        if(profiles.category === "featured"){
                                            return <ProfileCard key={index} url={profiles.url} isCharityMember={profiles.isCharityMember} img={profiles.img} username={profiles.username} tag={profiles.tag} tokenCount={profiles.tokenCount} />
                                        }
                                    })
                                }
                            </Row>
                            <Row className={"m-t-30"}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <h3 className={"m-b-20"}>Trending Professionals</h3>
                                </Col>
                                {
                                    feed.map((profiles, index)=>{
                                        if(profiles.category === "trending"){
                                            return <ProfileCard key={index} url={profiles.url} isCharityMember={profiles.isCharityMember} img={profiles.img} username={profiles.username} tag={profiles.tag} tokenCount={profiles.tokenCount} />
                                        }
                                    })
                                }
                            </Row>
                            <Row className={"m-t-30"}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <h3 className={"m-b-20"}>Athletes</h3>
                                </Col>
                                {
                                    feed.map((profiles, index)=>{
                                        if(profiles.category === "athletes"){
                                            return <ProfileCard key={index} url={profiles.url} isCharityMember={profiles.isCharityMember} img={profiles.img} username={profiles.username} tag={profiles.tag} tokenCount={profiles.tokenCount} />
                                        }
                                    })
                                }
                            </Row>
                            <Row className={"m-t-30"}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <h3 className={"m-b-20"}>Musicians</h3>
                                </Col>
                                {
                                    feed.map((profiles, index)=>{
                                        if(profiles.category === "musicians"){
                                            return <ProfileCard key={index} url={profiles.url} isCharityMember={profiles.isCharityMember} img={profiles.img} username={profiles.username} tag={profiles.tag} tokenCount={profiles.tokenCount} />
                                        }
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default MyFeed