import React from "react";
import { Outlet } from "react-router-dom";
import UserNavigation from "../../components/navigations/UserNavigation";

function ChatLayout() {
    return (
        <div className="ChatLayout">
            <UserNavigation />
            <div className={"ChatContainer"}>
                <Outlet />
            </div>
        </div>
    );
}

export default ChatLayout;
