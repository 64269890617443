import React, {useState} from "react";
import {Col, Container, Row, Button, Form, FloatingLabel} from "react-bootstrap";
import {AddIcon, ApplePay, ArrowIcon, ChevronRight, Star, VisaIcon} from "../components/SvgComponents";
import AccSidebar from "../components/AccSidebar";
import { ToastContainer, toast } from 'react-toastify';
import ThemeModal from "../components/ThemeModal";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";
import {useForm} from "react-hook-form";





function Payment() {
    const [cardModalShow, setCardModalShow] = useState(false);
    const [tokenModalShow, setTokenModalShow] = useState(false);
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });
    /*
    * Add new Card Details
    * */
    const onCardDetailsSubmit = (data) => {
        console.log(data);
    }
    const CardModalContent = () => {
        return(
            <div className={"cardDetailsModal"}>
                <h4>Card details</h4>
                <form className={"addNewCardDetails"} autoComplete={"off"} onSubmit={handleSubmit(onCardDetailsSubmit)}>
                    <div className={"mb13"}>
                        <FloatingLabel
                            controlId="formCard"
                            label="Card number"
                        >
                            <Form.Control type="number" autoComplete={"off"} placeholder="Credit Card"/>
                        </FloatingLabel>

                    </div>
                    <div className={"mb13"}>
                        <FloatingLabel
                            controlId="cardName"
                            label="Cardholder name"
                        >
                            <Form.Control type="text" autoComplete={"off"} placeholder="First Name"
                                          {...register("cardName",
                                              {
                                                  maxLength: {
                                                      value: VALIDATIONS.NAME,
                                                      message: VALIDATIONS_TEXT.NAME_MAX
                                                  },
                                                  required: {
                                                      value: true,
                                                      message: VALIDATIONS_TEXT.NAME_REQUIRED
                                                  },
                                              })
                                          }/>
                        </FloatingLabel>
                        {errors.cardName &&
                        <span className={"validation-error"} role="alert">{errors.cardName.message}</span>}
                    </div>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className={"mb13"}>
                                <FloatingLabel
                                    controlId="cardExpiry"
                                    label="Expiry date"
                                >
                                    <Form.Control type="date" autoComplete={"off"} placeholder="Expiry Date"
                                                  {...register("cardExpiry",
                                                      {
                                                          required: {
                                                              value: true,
                                                              message: VALIDATIONS_TEXT.NAME_REQUIRED
                                                          },
                                                      })
                                                  }/>
                                </FloatingLabel>
                                {errors.cardExpiry &&
                                <span className={"validation-error"} role="alert">{errors.cardExpiry.message}</span>}
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className={"mb13"}>
                                <FloatingLabel
                                    controlId="cardCvv"
                                    label="CVV"
                                >
                                    <Form.Control type="text" autoComplete={"off"} placeholder="CVV"
                                                  {...register("cardCVV",
                                                      {
                                                          minLength: {
                                                              value: VALIDATIONS.CVV,
                                                              message: VALIDATIONS_TEXT.CVV_MIN
                                                          },
                                                          maxLength: {
                                                              value: VALIDATIONS.CVV,
                                                              message: VALIDATIONS_TEXT.CVV_MAX
                                                          },
                                                          required: {
                                                              value: true,
                                                              message: VALIDATIONS_TEXT.CVV_REQUIRED
                                                          },
                                                      })
                                                  }/>
                                </FloatingLabel>
                                {errors.cardCVV &&
                                <span className={"validation-error"} role="alert">{errors.cardCVV.message}</span>}
                            </div>
                        </Col>
                    </Row>
                    <Button type={"submit"} className={"btn-solid w-100"}>
                        Save
                    </Button>
                </form>
            </div>
        )
    }

    /*
    * Purchase new tokens
    * */
    const TokensList = [{
            tokenCount: "30",
            pricePerToken: "$0.5",
            price: "$15",
        },
        {
            tokenCount: "90",
            pricePerToken: "$0.4",
            price: "$36",
            savingAmount: "$25",
        },
        {
            tokenCount: "120",
            pricePerToken: "$0.375",
            price: "$30",
            savingAmount: "$45",
            isRecomended: true,
        },
        {
            tokenCount: "200",
            pricePerToken: "$0.3",
            price: "$60",
            savingAmount: "$30",
        },
        {
            tokenCount: "300",
            pricePerToken: "$0.27",
            price: "$80",
            savingAmount: "$40",
        }
        ];
    const TokenComponent = (props) => {
        return(
            <Form.Check type={"radio"} id={"token30"} className={"form-check-tokens"}>
                <Form.Check.Input type={"radio"} name={"selectedTokenPackage"}/>
                <Form.Check.Label>
                    <div className={"TokenCount"}>
                        <Star />
                        <span className={"countContainer"}>
                            <span className={"count"}>30</span>
                            <span className={"text"}>tokens</span>
                        </span>
                    </div>
                    <div className={"costPerToken"}>
                        <p>$0.5 / token</p>
                    </div>
                    <div className={"priceToken"}>
                        <p>
                            $15 <small>Save $15</small>
                        </p>
                        <ArrowIcon />
                    </div>
                </Form.Check.Label>
            </Form.Check>
        )
    }
    const PurchaseTokensModal = () => {
        return(
            <div className={"purchaseTokensModal"}>
                <p>Quam pulvinar nullam quam nunc.</p>
                    <ul className={"list-unstyled listTokens"}>
                        <li>
                            <Form.Check type={"radio"} id={"token30"} className={"form-check-tokens"}>
                                <Form.Check.Input type={"radio"} name={"selectedTokenPackage"}/>
                                <Form.Check.Label>
                                        <div className={"TokenCount"}>
                                            <Star />
                                            <span className={"countContainer"}>
                                                <span className={"count"}>30</span>
                                                <span className={"text"}>tokens</span>
                                            </span>
                                        </div>
                                    <div className={"costPerToken"}>
                                        <p>$0.5 / token</p>
                                    </div>
                                    <div className={"priceToken"}>
                                        <p>
                                            $15 <small>Save $15</small>
                                        </p>
                                        <ArrowIcon />
                                    </div>
                                </Form.Check.Label>
                            </Form.Check>
                        </li>
                        <li>
                            <Form.Check type={"radio"} id={"token90"} className={"form-check-tokens"}>
                                <Form.Check.Input type={"radio"} name={"selectedTokenPackage"}/>
                                <Form.Check.Label>
                                    <div className={"TokenCount"}>
                                        <Star />
                                        <span className={"countContainer"}>
                                                <span className={"count"}>90</span>
                                                <span className={"text"}>tokens</span>
                                            </span>
                                    </div>
                                    <div className={"costPerToken"}>
                                        <p>$0.5 / token</p>
                                    </div>
                                    <div className={"priceToken"}>
                                        <p>
                                            $15 <small>Save $15</small>
                                        </p>
                                        <ArrowIcon />
                                    </div>
                                </Form.Check.Label>
                            </Form.Check>
                        </li>
                    </ul>
            </div>
        )
    }


    return (
        <div className={"mainDashboard accountSettings"}>
            <div className={"ProfileInformation"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} className={"col-xxl-4 d-none d-md-block"}>
                                    <AccSidebar />
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={8} xl={{span: 7, offset: 1}} className={"col-xxl-7 offset-xxl-1"}>
                                    <h1 className={"m-b-30"}>Payment</h1>
                                    <div className={"tokenBox"}>
                                        <div className={"tokenCountContainer"}>
                                            <h5>Tokens balance</h5>
                                            <p className={"tokenCount"}>
                                                <Star /> 120
                                            </p>
                                        </div>
                                        <div className={"btnContainer"}>
                                            <Button type={"button"} className={"btnTopup"} onClick={()=>setTokenModalShow(true)}>
                                                Top up <ChevronRight />
                                            </Button>
                                        </div>
                                    </div>
                                    <h4 className={"m-t-40"}>Payment methods</h4>

                                    <ul className={"list-unstyled listCards"}>
                                        <li>
                                            <Form.Check type={"radio"} id={"applePay"} className={"form-check-payments"}>
                                                <Form.Check.Label>
                                                    <ApplePay /> <sup>***</sup> 1234
                                                </Form.Check.Label>
                                                <Form.Check.Input type={"radio"} name={"defaultPay"}/>
                                            </Form.Check>
                                        </li>
                                        <hr/>
                                        <li>
                                            <Form.Check type={"radio"} id={"visaPay"} className={"form-check-payments"}>
                                                <Form.Check.Label>
                                                    <VisaIcon /> <sup>***</sup> 9124
                                                </Form.Check.Label>
                                                <Form.Check.Input type={"radio"} name={"defaultPay"}/>
                                            </Form.Check>
                                        </li>
                                        <hr/>
                                        <li>
                                            <div className={"addNewPayment"} onClick={()=>setCardModalShow(true)}>
                                                <div className={"svgContainer"}>
                                                    <AddIcon />
                                                    <span>Add debit / credit card</span>
                                                </div>
                                                <div>
                                                    <ArrowIcon />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <ToastContainer />
                    <ThemeModal title={"Add Card"} content={<CardModalContent />} className={"addCardInfo"} size={"sm"}
                                show={cardModalShow}
                                onHide={() => {
                                    setCardModalShow(false)
                                    reset()
                                }} />
                    <ThemeModal title={"Purchase tokens"} content={<PurchaseTokensModal />} className={"addCardInfo"} size={"sm"}
                                show={tokenModalShow}
                                onHide={() => {
                                    setTokenModalShow(false)
                                    reset()
                                }} />
                </Container>
            </div>
        </div>
    );
}

export default Payment;
