import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import LogoImg from '../../../assets/images/logo/logo.svg';
import {NavLink} from "react-router-dom";
import {
    InboxIcon,
    DashboardIcon,
    Star,
    SecurityIcon,
    PaymentIcon,
    SettingsIcon,
    ProfileInfoIcon, FaqIcon, SupportIcon, SignOutIcon
} from "../SvgComponents";
import ProfileIcon from "../../../assets/images/others/profileImage.png"
import ProfileIconFallback from "../../../assets/images/others/profileUser.png"

const NavLinks = [{
        type: "link",
        url: "/",
        urlText: "Home",
        disabled: false
    },
    {
        type: "link",
        url: "/our-team",
        urlText: "Our Team",
        disabled: false
    },
    {
        type: "link",
        url: "/why-ourchat",
        urlText: "Why OurChat",
        disabled: false
    },
    {
        type: "link",
        url: "/contact",
        urlText: "Contact Us",
        disabled: false
    },
    {
        type: "dropdown",
        url: "/",
        urlText: "Services",
        dropdownItems: [{
            url: "/service-01",
            urlText: "Service 01",
            disabled: false
        },
            {
                url: "/service-02",
                urlText: "Service 02",
                disabled: true
            }],
    }];

const NavBtns = [{
    btnType: "outline",
    btnText: "Login",
    btnUrl: "/login",
},
    {
        btnType: "solid",
        btnText: "Sign up",
        btnUrl: "/sign-up",
    }];

const RenderProfileTile = (name, icon) => {
    return(
        <>
            <img className={"ProfileIcon"} src={icon} alt={""} title={""} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src=ProfileIconFallback;
            }}/>
            {name}
        </>
    )
}

function ProfessionalNavigation() {

    return (
        <Navbar bg="light" expand="lg" fixed="top" className={"ourChatNav ProfessionalNav"}>
            <Container fluid>
                <Navbar.Brand href="/">
                    <img src={LogoImg} alt={"Our Chat"} title={"Our Chat"} className={"img-fluid"} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                    >
                        <NavLink to={"/inbox/"} className={"nav-link"}>
                           <InboxIcon/>
                           Inbox
                        </NavLink>
                        <NavLink to={"/dashboard"} className={"nav-link"}>
                           <DashboardIcon /> Dashboard
                        </NavLink>
                    </Nav>
                    <div className="d-flex align-items-center">
                        <span className={"profileStats"}>
                            <Star/> <span>2140</span>
                        </span>
                        <NavDropdown align={"end"} title={RenderProfileTile("Claudlu Influencerson", ProfileIcon)} id={"ProfileDropdown"} className={"ProfileDropdown"}>
                            <NavLink to={"/myprofile"} className={"dropdown-item"}>
                               <ProfileInfoIcon /> Profile
                            </NavLink>
                            <NavLink to={"/security"} className={"dropdown-item"}>
                               <SecurityIcon /> Security
                            </NavLink>
                            <NavLink to={"/payment"} className={"dropdown-item"}>
                                <PaymentIcon /> Payment
                            </NavLink>
                            <NavLink to={"/settings"} className={"dropdown-item"}>
                                <SettingsIcon /> Settings
                            </NavLink>
                            <NavDropdown.Divider />
                            <NavLink to={"/faqs"} className={"dropdown-item"}>
                                <FaqIcon /> FAQ
                            </NavLink>
                            <NavLink to={"/support"} className={"dropdown-item"}>
                                <SupportIcon /> Support
                            </NavLink>
                            <NavDropdown.Divider />
                            <NavLink to={"/"} className={"dropdown-item"}>
                                <SignOutIcon /> Logout
                            </NavLink>
                        </NavDropdown>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default ProfessionalNavigation;