import React from "react";
import { Outlet } from "react-router-dom";
import authImg from "../../assets/images/auth/intro.png";
import logoImg from "../../assets/images/logo/logo.svg";
import { ToastContainer, toast } from 'react-toastify';

function AuthLayout() {
  return (
    <div className="AuthLayout">
        <div className={"authenticationLayout"}>
            <div className={"left"}>
                <div className={"logoContainer"}>
                    <img src={logoImg} alt={"Our Chat"} className={"img-fluid"}/>
                </div>
                <div className={"componentContainer"}>
                    <Outlet />
                </div>
            </div>
            <div className={"right"}>
                <img src={authImg} alt={"Our Chat"} className={"img-fluid my-auto mx-auto"}/>
            </div>
        </div>
        <ToastContainer />
    </div>
  );
}

export default AuthLayout;
