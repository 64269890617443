import React from "react";
import {Dropdown, DropdownButton, Form} from "react-bootstrap";
import {ArchiveIcon, BlockIcon, ReportIcon, VerticalIcon} from "../components/SvgComponents";
import Profile01 from "../../assets/images/others/feed/feed1.png"
import Profile02 from "../../assets/images/others/feed/feed2.png"
import Profile03 from "../../assets/images/others/feed/feed3.png"
import Profile04 from "../../assets/images/others/feed/feed4.png"
import Profile05 from "../../assets/images/others/feed/feed5.png"

const ChatTilesList = [{
        name: "Logan Klitsch",
        imgUrl: Profile01,
        isActive: true,
        lastSeen: "Now",
        activeTyping: true,
        isUnderAge: false,
        chatUrl: "/chat_profile/01",
        addedNotes: false,
        addedNotesDesc: "",
    },
    {
        name: "Logan Klitsch",
        imgUrl: Profile01,
        isActive: true,
        lastSeen: "Now",
        activeTyping: true,
        isUnderAge: false,
        chatUrl: "/chat_profile/01",
        addedNotes: false,
        addedNotesDesc: "",
    },
    {
        name: "Logan Klitsch",
        imgUrl: Profile01,
        isActive: true,
        lastSeen: "Now",
        activeTyping: true,
        isUnderAge: false,
        chatUrl: "/chat_profile/01",
        addedNotes: false,
        addedNotesDesc: "",
    }
];

function Chat() {
    return (
        <div className={"ChatWindow"}>
            <div className={"ChatSidebar"}>
                <div className={"ChatSidebarTop"}>
                    <div className={"headingContainer"}>
                        <div className={"headings"}>
                            <h1>Inbox</h1>
                            <div className={"dropdownContainer"}>
                                <DropdownButton id="inboxBtn" align={"end"} title={<VerticalIcon/>}>
                                    <Dropdown.Item as="button"><ArchiveIcon /> View archive</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                        <div className={"searchBarContainer"}>
                            <Form.Control type="text" placeholder="Search messages..." className={"searchBarLeft"}/>
                        </div>
                    </div>
                </div>
                <div className={"chatTilesContainer"}>
                    <ul className={"tilesList"}>
                        <li className={""}>
                            <div className={"tileComponent isActive"}>
                                <div className={"profileImgContainer"}>
                                    <div className={"imgTile"}>
                                        <img className={"img-fluid"} src={Profile01} alt={"Logan"} title={"Logan"} />
                                    </div>
                                </div>
                                <div className={"profileDetails"}>
                                    <div className={"profileDetailsHeader"}>
                                        <div className={"profileName"}>Logan Klitsch</div>
                                        <div className={"activeStatus"}>Now</div>
                                    </div>
                                    <p>You: Curabitur id ex et ex pharetra mollis. Duis placerat, augue.</p>


                                </div>
                            </div>
                        </li>
                        <li className={""}>
                            <div className={"tileComponent isActive"}>
                                <div className={"profileImgContainer"}>
                                    <div className={"imgTile"}>
                                        <img className={"img-fluid"} src={Profile01} alt={"Logan"} title={"Logan"} />
                                    </div>
                                </div>
                                <div className={"profileDetails"}>
                                    <div className={"profileDetailsHeader"}>
                                        <div className={"profileName"}>Logan Klitsch</div>
                                        <div className={"activeStatus"}>Now</div>
                                    </div>
                                    <p>You: Curabitur id ex et ex pharetra mollis. Duis placerat, augue.</p>


                                </div>
                            </div>
                        </li>
                        <li className={""}>
                            <div className={"tileComponent isActive"}>
                                <div className={"profileImgContainer"}>
                                    <div className={"imgTile"}>
                                        <img className={"img-fluid"} src={Profile01} alt={"Logan"} title={"Logan"} />
                                    </div>
                                </div>
                                <div className={"profileDetails"}>
                                    <div className={"profileDetailsHeader"}>
                                        <div className={"profileName"}>Logan Klitsch</div>
                                        <div className={"activeStatus"}>Now</div>
                                    </div>
                                    <p>You: Curabitur id ex et ex pharetra mollis. Duis placerat, augue.</p>


                                </div>
                            </div>
                        </li>
                        <li className={""}>
                            <div className={"tileComponent isActive"}>
                                <div className={"profileImgContainer"}>
                                    <div className={"imgTile"}>
                                        <img className={"img-fluid"} src={Profile01} alt={"Logan"} title={"Logan"} />
                                    </div>
                                </div>
                                <div className={"profileDetails"}>
                                    <div className={"profileDetailsHeader"}>
                                        <div className={"profileName"}>Logan Klitsch</div>
                                        <div className={"activeStatus"}>Now</div>
                                    </div>
                                    <p>You: Curabitur id ex et ex pharetra mollis. Duis placerat, augue.</p>


                                </div>
                            </div>
                        </li>
                        <li className={""}>
                            <div className={"tileComponent isActive"}>
                                <div className={"profileImgContainer"}>
                                    <div className={"imgTile"}>
                                        <img className={"img-fluid"} src={Profile01} alt={"Logan"} title={"Logan"} />
                                    </div>
                                </div>
                                <div className={"profileDetails"}>
                                    <div className={"profileDetailsHeader"}>
                                        <div className={"profileName"}>Logan Klitsch</div>
                                        <div className={"activeStatus"}>Now</div>
                                    </div>
                                    <p>You: Curabitur id ex et ex pharetra mollis. Duis placerat, augue.</p>


                                </div>
                            </div>
                        </li>
                        <li className={""}>
                            <div className={"tileComponent isActive"}>
                                <div className={"profileImgContainer"}>
                                    <div className={"imgTile"}>
                                        <img className={"img-fluid"} src={Profile01} alt={"Logan"} title={"Logan"} />
                                    </div>
                                </div>
                                <div className={"profileDetails"}>
                                    <div className={"profileDetailsHeader"}>
                                        <div className={"profileName"}>Logan Klitsch</div>
                                        <div className={"activeStatus"}>Now</div>
                                    </div>
                                    <p>You: Curabitur id ex et ex pharetra mollis. Duis placerat, augue.</p>


                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={"ChatMainWindow"}>

            </div>
        </div>
    );
}

export default Chat;
