import React from "react";
import {Link, NavLink} from "react-router-dom";
import {
    ArrowIcon,
    FaqIcon,
    PaymentIcon,
    ProfileInfoIcon,
    SecurityIcon,
    SettingsIcon,
    SupportIcon
} from "./SvgComponents";
import profileImg from "../../assets/images/others/profileUser.png";
import {Col} from "react-bootstrap";

const ProfileCard = (props) => {
    return(
        <div className={"profileCard"}>
            <div className={"imgContainer"}>
                <img className={"img-fluid"} src={props.img} alt={"profileImg"} title={"profileImg"} />
            </div>
            <div className={"profileDetails"}>
                <span className={"profileName"}>{props.name}</span>
                <span className={"profileEmail"}>{props.email}</span>
                <Link className={"profileLink"} to={"/myprofile"}>View profile <ArrowIcon /></Link>
            </div>
        </div>
    )
}

function AccSidebar() {
    return (
        <div>
            <ProfileCard img={profileImg} name={"Daniel Stevenson"} email={"dansteve92@gmail.com"}/>
            <hr/>
            <h4>Options</h4>
            <div className={"accSideBar"}>
                <ul className={"list-unstyled"}>
                    <li className={"sidebar-item"}>
                        <NavLink to={"/myprofile"}>
                            <div className={"d-flex align-items-center justify-content-between w-100"}>
                                <span className={"svgContainer"}><ProfileInfoIcon /> Profile Information</span>
                                <span><ArrowIcon /></span>
                            </div>
                        </NavLink>
                    </li>
                    <li className={"sidebar-item"}>
                        <NavLink to={"/security"}>
                            <div className={"d-flex align-items-center justify-content-between w-100"}>
                                <span className={"svgContainer"}><SecurityIcon /> Security</span>
                                <span><ArrowIcon /></span>
                            </div>
                        </NavLink>
                    </li>
                    <li className={"sidebar-item"}>
                        <NavLink to={"/payment"}>
                            <div className={"d-flex align-items-center justify-content-between w-100"}>
                                <span className={"svgContainer"}><PaymentIcon /> Payment</span>
                                <span><ArrowIcon /></span>
                            </div>
                        </NavLink>
                    </li>
                    <li className={"sidebar-item"}>
                        <NavLink to={"/settings"}>
                            <div className={"d-flex align-items-center justify-content-between w-100"}>
                                <span className={"svgContainer"}><SettingsIcon /> Settings</span>
                                <span><ArrowIcon /></span>
                            </div>
                        </NavLink>
                    </li>
                    <h4>Other</h4>
                    <li className={"sidebar-item"}>
                        <NavLink to={"/setting"}>
                            <div className={"d-flex align-items-center justify-content-between w-100"}>
                                <span className={"svgContainer"}><FaqIcon /> FAQ</span>
                                <span><ArrowIcon /></span>
                            </div>
                        </NavLink>
                    </li>
                    <li className={"sidebar-item"}>
                        <NavLink to={"/setting"}>
                            <div className={"d-flex align-items-center justify-content-between w-100"}>
                                <span className={"svgContainer"}><SupportIcon /> Support</span>
                                <span><ArrowIcon /></span>
                            </div>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default AccSidebar;
