import React, {useEffect} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./app/authentication/SignIn";
import AuthLayout from "./app/layouts/AuthLayout";
import OpenPageLayout from "./app/layouts/OpenPageLayout";

/************************
*  User Routes
************************/
import CreateAcountUser from './app/user/CreateAccountUser';
import MyFeed from "./app/user/MyFeed";
import UserProfile from "./app/user/UserProfile";


/************************
*  Professional Routes
************************/
import CreateAcount from "./app/professional/CreateAccount";
import AccountVerification from "./app/professional/AccountVerification";
import ForgotPassword from "./app/authentication/ForgotPassword";
import ResetPassword from "./app/authentication/ResetPassword";
import Success from "./app/supportingPages/Success";
import ConfigureProfile from "./app/professional/ConfigureProfile";
import DashboardLayout from "./app/layouts/DashboardLayout";
import Dashboard from "./app/professional/dashboard/Dashboard";

/**********************************************
 *  shared by both user and professional users
 **********************************************/
import ProfileInformation from "./app/accountSetting/ProfileInformation";
import SecurityInfo from "./app/accountSetting/SecurityInfo";
import Payment from "./app/accountSetting/Payment";
import Settings from "./app/accountSetting/Settings";
import Error from "./app/supportingPages/Error";

/**********************************************
 *  Chat Layout
 **********************************************/
import ChatLayout from "./app/chat/components/ChatLayout";
import Chat from "./app/chat/Chat";

import {useNavigate, useLocation} from "react-router-dom";

const RoutesComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.body.scrollTop = 0
    }, [navigate, location])
    return(
        <Routes>
            {/*
            Split layout right and left
        */}
            <Route element={<AuthLayout />}>
                <Route index path="/" element={<SignIn />} />
                <Route exact path="/create-account-professional" element={<CreateAcount />} />
                <Route exact path="/sign-up" element={<CreateAcountUser />} />
                <Route exact path="/account-verification-professional" element={<AccountVerification />} />
            </Route>
            {/*Open Page Layouts*/}
            <Route element={<OpenPageLayout />}>
                <Route exact path="/forgot-password" element={<ForgotPassword />} />
                <Route exact path="/reset-password" element={<ResetPassword />} />

                <Route exact path="/configure-profile" element={<ConfigureProfile />} />

                {/*
              * Success Pages
              */}

                <Route exact path="/success" element={<Success type={"reset-password"}/>} />
                <Route exact path="/success-verification" element={<Success type={"verification-code"}/>} />
                <Route exact path="/success-verified-profile" element={<Success type={"account-verified"}/>} />
                <Route exact path="/account-created-success" element={<Success type={"account-created"}/>} />
                <Route exact path="/password-changed-success" element={<Success type={"verification-code"}/>} />
                <Route exact path="*" element={<Error type={"404"}/>} />
            </Route>

            <Route element={<DashboardLayout />}>
                {/*professional views*/}
                <Route exact path="/dashboard" element={<Dashboard />} />

                {/*users views*/}
                <Route exact path="/myfeed" element={<MyFeed />} />
                <Route exact path="/user_profile/:id" element={<UserProfile />} />

                {/*Account Settings shared by both professional and user*/}
                <Route exact path="/myprofile" element={<ProfileInformation />} />
                <Route exact path="/security" element={<SecurityInfo />} />
                <Route exact path="/payment" element={<Payment />} />
                <Route exact path="/settings" element={<Settings />} />
            </Route>
            <Route element={<ChatLayout />}>
                <Route exact path="/inbox" element={<Chat />}  />
            </Route>
        </Routes>
    )
}

function RouteConfig() {
  return (
    <BrowserRouter>
      <RoutesComponent />
    </BrowserRouter>
  );
}

export default RouteConfig;
