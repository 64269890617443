import RouteConfig from "./RouteConfig";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/our-chat.css';

function App() {
  return (
    <div className="App">
      <RouteConfig />
    </div>
  );
}

export default App;
