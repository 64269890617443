import React, {useState} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import AccountNotification from "../../components/AccountNotification";
import {EmptyListIcon} from "../../components/SvgComponents";

function Dashboard() {
    const [verificationStatus, setVerificationStatus] = useState(false);
    return (
        <div className={"mainDashboard"}>
            <div className={"dashboardProfessional"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <AccountNotification />
                            <h1 className={"m-t-30"}>Dashboard</h1>
                            <Row className={"m-t-20"}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} className={""}>
                                    <h4>Revenue</h4>
                                    <div className={"statBox"}>
                                        <Row className={"align-items-center"}>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <h2>$0</h2>
                                                <p>alltime revenue</p>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <ul className={"list-unstyled listStats"}>
                                                    <li>
                                                        <div>
                                                            <span>Today</span>
                                                            <span>$0</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <span>This week</span>
                                                            <span>$0</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <span>This month</span>
                                                            <span>$0</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>

                                    <h4 className={"m-t-30"}>Pending requests</h4>
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <div className={"requestBox isPending"}>
                                                <h4>0</h4>
                                                <p>regular requests</p>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <div className={"requestBox isUrgent"}>
                                                <h4>0</h4>
                                                <p>urgent requests</p>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <div className={"requestBox isLocked"}>
                                                <h4>0</h4>
                                                <p>locked requests</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={{span: 5, offset: 1}} xl={{span: 5, offset: 1}}>
                                    <h4>Latest requests</h4>
                                    <div className={"requestList isEmpty"}>
                                        <div className={"EmptyIconBox h-100"}>
                                            <div className={"mx-auto text-center h-100 d-flex flex-column align-contents-center justify-content-center align-items-center"}>
                                                <EmptyListIcon />
                                                <p>No request yet. Lorem<br/> ipsum dolor sit.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Dashboard