import React, {useState, useRef} from "react";
import {Button, Form, InputGroup} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {LeftIcon, SuccessNotificationIcon} from "../components/SvgComponents";
import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai";
import {useForm} from "react-hook-form";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";
import {toast} from "react-toastify";


function ResetPassword() {
    const navigate = useNavigate();
  const [password, setPassword] = useState(false);
  const [cPassword, setCpassword] = useState(false);


    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const onSubmit = (data) => {
        console.log(data);
        toast.success("Password reset success", {
            icon: <SuccessNotificationIcon />,
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setTimeout(() => {
            navigate("/password-changed-success");
        }, 5000);

    }

    const passwordWatch = useRef({});
    passwordWatch.current = watch("password", "");

  return (
      <div className={"viewportFull"}>
          <div className={"d-flex align-items-center justify-content-center h-100"}>
              <div className={"componentContainer"}>
                  <div className={"resetPassword"}>
                      <h1>Reset password</h1>
                      <p>Curabitur id ex et ex pharetra mollis. Duis<br/> placerat, augue vel accumsan feugiat.</p>

                      <Form className={"m-t-40"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                          <div className={"mb13"}>
                              <InputGroup className={"passwordGroup"}>
                                  <Form.Control
                                      type={password ? "text" : "password"} placeholder="New password"
                                      aria-label="password"
                                      autoComplete={"off"}
                                      aria-describedby="inputGroup-sizing-sm"
                                      className={"mb-0"}
                                      {...register("password",
                                          {
                                              minLength: {
                                                  value: VALIDATIONS.PASSWORD_MIN,
                                                  message: VALIDATIONS_TEXT.PASSWORD_MIN
                                              },
                                              maxLength: {
                                                  value: VALIDATIONS.PASSWORD_MAX,
                                                  message: VALIDATIONS_TEXT.PASSWORD_MAX
                                              },
                                              required: {
                                                  value: true,
                                                  message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                              },
                                          })
                                      }
                                  />
                                  <Button variant="outline-secondary" id="button-addon1" onClick={()=>setPassword(!password)}>
                                      {password ? <AiFillEyeInvisible /> : <AiFillEye/>}
                                  </Button>
                              </InputGroup>
                              {errors.password &&
                              <span className={"validation-error"} role="alert">{errors.password.message}</span>}
                          </div>
                          <div className={"mb13"}>
                              <InputGroup className={"passwordGroup"}>
                                  <Form.Control
                                      type={cPassword ? "text" : "password"} placeholder="Confirm new password"
                                      aria-label="password"
                                      autoComplete={"off"}
                                      aria-describedby="inputGroup-sizing-sm"
                                      className={"mb-0"}
                                      {...register('Cpassword', {
                                          required: {
                                              value: "required",
                                              message: "Confirm password is required"
                                          },
                                          validate: value => value === passwordWatch.current || "The passwords do not match",
                                          minLength: {
                                              value: 8,
                                              message: "Min length is 8"
                                          },
                                          maxLength: {
                                              value: 255,
                                              message: "Max length is 255"
                                          },
                                      })}
                                  />
                                  <Button variant="outline-secondary" id="button-addon2" onClick={()=>setCpassword(!cPassword)}>
                                      {cPassword ? <AiFillEyeInvisible /> : <AiFillEye/>}
                                  </Button>
                              </InputGroup>
                              {errors.Cpassword &&
                              <span className={"validation-error"} role="alert">{errors.Cpassword.message}</span>}
                          </div>
                          <Button type={"submit"} className={"w-100 btn-solid m-t-30"}>
                              Reset password
                          </Button>
                      </Form>

                      <div className={"mx-auto text-center m-t-40"}>
                          <Link to={"/"} className={"backToBtn"}>
                              <LeftIcon /> Back to login
                          </Link>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default ResetPassword;
