import React, {useEffect, useState} from 'react'
import {Container, Row, Col, DropdownButton, Dropdown, Button, Form} from "react-bootstrap"
import {Dot, LeftIcon, Star, VerticalIcon, ChatIconOutline, BlockIcon, ReportIcon} from "../components/SvgComponents";
import {Link} from "react-router-dom"
import profileImg from "../../assets/images/others/feed/feed7-lg.png"
import ReadMore from "../components/ReadMore";
import ThemeModal from "../components/ThemeModal";
import {useParams} from "react-router-dom";

const Status = ({status}) => {
    if(status === "active"){
        return(
            <div className={"statusContainer"}>
                <span className={"status accepting"}>
                    <Dot /> Accepting messages
                </span>
            </div>
        )
    }
    else if(status === "notActive"){
        return(
            <div className={"statusContainer"}>
                <span className={"status notAccepting"}>
                    <Dot /> Not accepting messages
                </span>
            </div>
        )
    }
    else if(status === "blocked"){
        return(
            <div className={"statusContainer"}>
                <span className={"status blocked"}>
                    You have blocked this person
                </span>
            </div>
        )
    }

}

function UserProfile(props) {

    const [modalBlockShow, setModalBlockShow] = useState(false);
    const [modalReportShow, setModalReportShow] = useState(false);
    const [UserProfileDetails, setUserProfileDetails] = useState([{
        profileId: 1,
        profileName: "David Johnson",
        ageLimit: "Under 18",
        isCharityMember: true,
        status: "active",
        img: profileImg,
        category: "Vlogger",
        bio: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum.",
        chatEnabled: true,
        tokensCount: "120",
        categoryTags: ["vlogging", "youtuber", "travel", "lifestyle", "nomad", "travel", "lifestyle", "nomad"],
    }]);

    useEffect(()=>{
        getProfile();
    },[])

    const getProfile = (id) => {
        console.log("param id: ", id);
    }

    const BlockModalContent = () => {
        return(
            <div className={"blockProfile"}>
                <p>
                    Are you sure you want to<br/>
                    block this user?
                </p>
                <Row className={"g-3"}>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button type={"button"} className={"btn-solid active w-100"} onClick={()=>setModalBlockShow(false)}>
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button type={"button"} className={"btn-solid w-100"} onClick={()=>handleBlockProfile()}>
                                Block
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    const ReportModalContent = () => {
        return(
            <div className={"blockProfile"}>
                <p>
                    Reason to report
                </p>
                <form>
                    <Row className={"g-3 p-3"}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Check
                                reverse
                                label="Spam"
                                name="group1"
                                type={"checkbox"}
                                id={"checkboxSpam"}
                                className={"labelCheckbox"}
                            />
                            <Form.Check
                                reverse
                                label="False Information"
                                name="group1"
                                type={"checkbox"}
                                id={"checkboxFalseInfo"}
                                className={"labelCheckbox"}
                            />
                            <Form.Check
                                reverse
                                label="Harasement"
                                name="group1"
                                type={"checkbox"}
                                id={"checkboxHarasement"}
                                className={"labelCheckbox"}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button type={"submit"} className={"btn-solid w-100"} onClick={()=>handleBlockProfile()}>
                                Report
                            </Button>
                        </Col>
                    </Row>
                </form>
            </div>
        )
    }

    /*
    *  handle Block Click
    * */
    const handleBlockProfile = (id) => {
        console.log("Block profile Id: ", id);

    }
    /*
    *  handle Report Profile Click
    * */
    const handleReportProfile = (id) => {
        console.log("Report profile Id: ", id);
    }

    return (
        <div className={"mainDashboard"}>
            <div className={"SingleFeedProfile"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row className={"m-b-30"}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Link to={"/myfeed"} className={"linkText"}>
                                         <LeftIcon/> Back
                                    </Link>
                                </Col>
                            </Row>

                            {
                                UserProfileDetails?.map((data, index)=>{
                                    return (
                                        <Row className={"feedProfileBox"} key={index}>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} className={"col-xxl-5 offset-xxl-1"}>
                                                <div className={`imgContainer ${data.isCharityMember ? "charityMember" : "" }`}>
                                                    <img src={data.img} alt={data.profileName} title={data.profileName} className={"img-fluid"} />
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} className={"col-xxl-5 offset-xxl-1"}>
                                                <div className={"profileDescription"}>
                                                    <div className={"profileTitle"}>
                                                        <div className={"titleContainer"}>
                                                            <h1>{data.profileName}</h1>
                                                            {
                                                                data.ageLimit && <span className={"tag"}>{data.ageLimit}</span>
                                                            }
                                                        </div>
                                                        <div className={"dropdownContainer"}>
                                                            <DropdownButton id="dropdown-item-button" align={"end"} title={<VerticalIcon/>}>
                                                                <Dropdown.Item as="button" onClick={()=>setModalBlockShow(true)}><BlockIcon /> Block</Dropdown.Item>
                                                                <Dropdown.Item as="button" onClick={()=>setModalReportShow(true)}><ReportIcon /> Report profile</Dropdown.Item>
                                                            </DropdownButton>
                                                        </div>
                                                    </div>
                                                </div>

                                                <Status status={data.status}/>
                                                <p className={"streamingCategory"}>{data.category}</p>

                                                <h4>Bio</h4>
                                                <Row>
                                                    <Col xs={12} sm={12} md={9} lg={9} xl={9} className={"col-xxl-9"}>
                                                        {
                                                            data.bio && <ReadMore classList={"bioDetails"} textLength={"160"}>
                                                                {data.bio}
                                                            </ReadMore>
                                                        }

                                                    </Col>
                                                </Row>
                                                <Button className={"chatNow"} disabled={data.chatEnabled}>
                                                    <div className={"d-flex align-items-center"}>
                                                        <div className={"chatIcon"}>
                                                            <ChatIconOutline />
                                                        </div>
                                                        <div className={"d-flex align-items-center"}>
                                                            <span>Chat Now</span>
                                                            <span className={"starLabel"}><Star/> {data.tokensCount}</span>
                                                        </div>
                                                    </div>
                                                </Button>

                                                <h4 className={"m-b-20"}>Categories</h4>

                                                <ul className={"list-inline"}>
                                                    {
                                                        data.categoryTags?.map((tags, index)=>{
                                                            return(
                                                                <li className={"list-inline-item"} key={index}>
                                                                    <span className={"catTag"}>
                                                                        {tags}
                                                                    </span>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>

                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                    <ThemeModal title={"Block User"} content={<BlockModalContent />} className={"blockUser"} size={"sm"}
                                show={modalBlockShow}
                                onHide={() => {
                                    setModalBlockShow(false)
                                }}/>

                    <ThemeModal title={"Report User"} content={<ReportModalContent />} className={"reportUser"} size={"sm"}
                    show={modalReportShow}
                    onHide={() => {
                        setModalReportShow(false)
                    }}/>
                </Container>
            </div>
        </div>
    )
}

export default UserProfile