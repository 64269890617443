import React, {useEffect, useState} from "react";
import {SuccessIcon} from "../components/SvgComponents";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";


const ResetPasswordSuccess = () => {
    const navigate = useNavigate();
    useEffect(()=>{
        setTimeout(() => {
            navigate("/reset-password");
        }, 3000);
    })
    return(
        <div className={"codeSentSuccess"}>
            <div className={"mx-auto text-center"}>
                <div className={"m-b-50"}>
                    <SuccessIcon />
                </div>
                <h2>Check your email</h2>
                <p>Please follow the instructions that we have<br/> sent to your email.</p>
            </div>
            <div className={"mx-auto text-center m-t-40"}>
                <p className={"p-small"}>Didn’t get the code? <span onClick={()=>{console.log("code sent")}}>Send again</span></p>
            </div>
        </div>
    )
}
const PasswordChangeSuccess = () => {
    return(
        <div className={"passwordChangedSuccess"}>
            <div className={"mx-auto text-center"}>
                <div className={"m-b-50"}>
                    <SuccessIcon />
                </div>
                <h2>Password has been changed</h2>
                <p>Please follow the instructions that we have<br/> sent to your email and we’ll verify your<br/> account in no time!</p>
            </div>
            <div className={"mx-auto text-center m-t-40"}>
                <Link to={"/"} className={"btn-solid mx-auto"}>
                    Go to login
                </Link>
            </div>
        </div>
    )
}

const AccountVerifiedSuccess = () => {
    const navigate = useNavigate();
    useEffect(()=>{
        setTimeout(() => {
            navigate("/configure-profile");
        }, 3000);
    })
    return(
        <div className={"passwordChangedSuccess"}>
            <div className={"mx-auto text-center"}>
                <div className={"m-b-50"}>
                    <SuccessIcon />
                </div>
                <h2>Your account has been<br/> verified!</h2>
                <p>Please follow the instructions that we have sent to your<br/> email and we’ll verify your account in no time!</p>
            </div>
            <div className={"mx-auto text-center m-t-40"}>
                <Link to={"/"} className={"btn-solid mx-auto"}>
                    Login
                </Link>
            </div>
        </div>
    )
}

const AccountCreatedSuccess = () => {
    return(
        <div className={"passwordChangedSuccess"}>
            <div className={"mx-auto text-center"}>
                <div className={"m-b-50"}>
                    <SuccessIcon />
                </div>
                <h2>Your account has been<br/> successfully created</h2>
            </div>
            <div className={"mx-auto text-center m-t-40"}>
                <Button to={"/"} className={"btn-solid mx-auto"}>
                    Login me in
                </Button>
            </div>
        </div>
    )
}

const renderComponent = (type) => {
    console.log(type);
    if(type === "reset-password"){
      return <ResetPasswordSuccess />
    }
    else if(type === "verification-code"){
       return <PasswordChangeSuccess/>
    }
    else if(type === "account-verified"){
       return <AccountVerifiedSuccess/>
    }
    else if(type === "account-created"){
       return <AccountCreatedSuccess/>
    }
}

function Success({type}) {

    return (
        <div className={"viewportFull"}>
            <div className={"d-flex align-items-center justify-content-center h-100"}>
                <div className={"componentContainer"}>
                    <div className={"successPage"}>
                        {
                            renderComponent(type)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Success;
