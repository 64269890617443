import React from "react";
import {Col, Container, Row, Button, Form, FloatingLabel} from "react-bootstrap";
import profileImg from "../../assets/images/others/profileUser.png";
import {Link, NavLink} from "react-router-dom";
import {ArrowIcon} from "../components/SvgComponents";
import AccSidebar from "../components/AccSidebar";
import {useForm} from "react-hook-form";
import {Patterns, VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";



function ProfileInformation() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const onSubmit = (data) => {
        console.log(data);


    }

    return (
        <div className={"mainDashboard accountSettings"}>
            <div className={"ProfileInformation"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} className={"col-xxl-4 d-none d-md-block"}>

                                    <AccSidebar />
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={8} xl={{span: 7, offset: 1}} className={"col-xxl-7 offset-xxl-1"}>
                                    <h1>Profile information</h1>
                                    <form className={"profileInfoForm"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                                        <h4>Profile picture</h4>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                                <div className={"profileChange"}>
                                                    <div className={"imgContainer"}>
                                                        <img className={"img-fluid"} src={profileImg} alt={"profileImg"} title={"profileImg"} />
                                                    </div>
                                                    <div className={"w-100"}>
                                                        <Form.Group controlId="formFile" className="inputFileBtn">
                                                            <Form.Label className={"d-block w-100"}>Change Avatar</Form.Label>
                                                            <Form.Control type="file" name={"fileProfile"} accept="image/png,image/jpeg,image/jpg"/>
                                                        </Form.Group>
                                                        <Button type={"button"} className={"btnSolid active w-100"}>
                                                            Remove
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <h4>Account info</h4>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formNameF"
                                                label="First Name"
                                            >
                                                <Form.Control type="text" autoComplete={"off"} placeholder="First Name"
                                                              {...register("fName",
                                                                  {
                                                                      maxLength: {
                                                                          value: VALIDATIONS.NAME,
                                                                          message: VALIDATIONS_TEXT.NAME_MAX
                                                                      },
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.FNAME_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.fName &&
                                            <span className={"validation-error"} role="alert">{errors.fName.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formNameL"
                                                label="Last Name"
                                            >
                                                <Form.Control type="text" autoComplete={"off"} placeholder="Last Name"
                                                              {...register("lName",
                                                                  {
                                                                      maxLength: {
                                                                          value: VALIDATIONS.NAME,
                                                                          message: VALIDATIONS_TEXT.NAME_MAX
                                                                      },
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.LNAME_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.lName &&
                                            <span className={"validation-error"} role="alert">{errors.lName.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formEmail"
                                                label="Email address"
                                            >
                                                <Form.Control type="text" autoComplete={"off"} placeholder="Email address"
                                                              {...register("email",
                                                                  {
                                                                      maxLength: {
                                                                          value: VALIDATIONS.EMAIL,
                                                                          message: VALIDATIONS_TEXT.EMAIL_MAX
                                                                      },
                                                                      pattern: {
                                                                          value: Patterns.Email,
                                                                          message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                                      },
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.email &&
                                            <span className={"validation-error"} role="alert">{errors.email.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formUsername"
                                                label="Username"
                                            >
                                                <Form.Control type="text" autoComplete={"off"} placeholder="Username"
                                                              {...register("userName",
                                                                  {
                                                                      maxLength: {
                                                                          value: VALIDATIONS.USERNAME,
                                                                          message: VALIDATIONS_TEXT.USERNAME_MAX
                                                                      },
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.USERNAME_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.userName &&
                                            <span className={"validation-error"} role="alert">{errors.userName.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formUsername"
                                                label="Date of birth"
                                            >
                                                <Form.Control type="date" autoComplete={"off"} placeholder="Date of birth"
                                                              {...register("dob",
                                                                  {
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.DOB_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.dob &&
                                            <span className={"validation-error"} role="alert">{errors.dob.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formLocation"
                                                label="Location"
                                            >
                                                <Form.Select aria-label="Location" {...register("location",
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.LOCATION_REQUIRED
                                                        },
                                                    })
                                                }>
                                                    <option>Select Location</option>
                                                    <option value="1">New York, NY</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                            {errors.location &&
                                            <span className={"validation-error"} role="alert">{errors.location.message}</span>}
                                        </div>
                                        <Button type={"submit"} className={"btn-solid w-100 m-t-30"}>
                                            Save
                                        </Button>
                                    </form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default ProfileInformation;
