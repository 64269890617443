import React from 'react'
import {Container, Row, Col, Form, FloatingLabel, FormLabel, Button} from "react-bootstrap";
import ProfileImg from "../../assets/images/others/profileImage.png"
import {MdDeleteOutline} from "react-icons/md"
/*
* React tags input
* */
import { render } from 'react-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import {useNavigate} from "react-router-dom";

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];


function ConfigureProfile() {
    let navigate = useNavigate();
    /*
    * React Tags Input ADD, delete and click
    * */
    const [tags, setTags] = React.useState([]);
    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    const handleTagClick = index => {
        console.log('The tag at index ' + index + ' was clicked');
    };

    const RemoveTagComponent = (props) => {
        const { className, onRemove } = props;
        return (
            <button onClick={onRemove} className={className}>
                <MdDeleteOutline/>
            </button>
        )
    }

    return (
        <div className={"configureProfile"}>
            <Container>
                <Row className={"m-b-30"}>
                    <Col xs={12} sm={12} md={{ span: 4, offset: 1 }} lg={{ span: 4, offset: 1 }} xl={{ span: 4, offset: 1 }}>
                        <h1>Configure profile</h1>
                        <p>Quam pulvinar nullam quam nunc.</p>
                    </Col>
                    <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                        <div className={"text-end"}>
                            <button className={"skipLink"} onClick={()=>navigate('/dashboard')}>Skip for later</button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={{ span: 4, offset: 1 }} lg={{ span: 4, offset: 1 }} xl={{ span: 4, offset: 1 }}>
                        <img className={"img-fluid imgProfile"} src={ProfileImg} alt={"Profile Img"} title={"Profile Img"} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src=ProfileImg;
                        }}/>
                        <Form.Group controlId="profileImg" className="LabelFile m-t-10">
                            <Form.Label>Change image</Form.Label>
                            <Form.Control type="file" />
                        </Form.Group>
                        <div className={"m-t-40"}></div>
                        <h3>Status</h3>
                        <div className={"checkBox-container m-b-20"}>
                            <div className={"checkBox-Group"}>
                                <FormLabel className={""} htmlFor={"takingMessages"}>
                                    Taking messages
                                </FormLabel>
                                <Form.Check
                                    type="switch"
                                    id="takingMessages"
                                />
                            </div>
                            <p>Curabitur id ex et ex pharetra mollis.</p>
                        </div>
                        <div className={"checkBox-container m-b-20"}>
                            <div className={"checkBox-Group"}>
                                <FormLabel className={""} htmlFor={"charityMode"}>
                                    Charity mode
                                </FormLabel>
                                <Form.Check
                                    type="switch"
                                    id="charityMode"
                                />
                            </div>
                            <p>Curabitur id ex et ex pharetra mollis.</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                        <h3 className={"m-b-10"}>About</h3>
                        <FloatingLabel
                            controlId="userName"
                            label="Username"
                            className="mb-3"
                        >
                            <Form.Control type="text" placeholder="clauinfuencer" />
                        </FloatingLabel>
                        <FloatingLabel controlId="location" label="Location" className={"mb-3"}>
                            <Form.Select aria-label="Floating label select example">
                                <option>Select Location</option>
                                <option value="1">New York, NY</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="Profession"
                            label="Profession"
                            className="mb-3"
                        >
                            <Form.Control type="text" placeholder="Athlete" />
                        </FloatingLabel>
                        <FloatingLabel controlId="bio" label="Enter bio">
                            <Form.Control
                                as="textarea"
                                placeholder="Nunc congue egestas turpis proin nulla. Dui duis ac consequat in. Et tristique consectetur at ultricies."
                                style={{ height: '200px' }}
                            />
                        </FloatingLabel>
                        <div className={"m-t-30"}></div>
                        <h3>Pricing configuration</h3>
                        <Row className={""}>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className={"dualLabels"}>
                                    <FloatingLabel
                                        controlId="messagePrice"
                                        label="Message price"
                                        className="mb-3"
                                    >
                                        <Form.Control type="number" placeholder="20"/>
                                        <span>tokens</span>
                                    </FloatingLabel>
                                </div>

                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className={"dualLabels"}>
                                    <FloatingLabel
                                        controlId="messageLimit"
                                        label="Messages limit"
                                        className="mb-3"
                                    >
                                        <Form.Control type="number" placeholder="20" />
                                        <span>characters</span>
                                    </FloatingLabel>
                                </div>
                            </Col>
                        </Row>
                        <h3 className={"m-b-20"}>Pricing configuration</h3>
                        <h4>Add categories</h4>
                        <div className={"TagsContainer"}>
                            <ReactTags
                                tags={tags}
                                delimiters={delimiters}
                                handleDelete={handleDelete}
                                handleAddition={handleAddition}
                                handleTagClick={handleTagClick}
                                removeComponent={RemoveTagComponent}
                                inputFieldPosition="inline"
                                autofocus={false}
                                autocomplete
                            />
                        </div>
                        <div className={"m-t-20"}>
                            <Button type={"button"} className={"btn-solid btn-save ms-auto"} onClick={()=>navigate('/dashboard')}>
                                Save
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ConfigureProfile