import React, {useEffect, useState} from "react";
import {SuccessIcon} from "../components/SvgComponents";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";


const Error404 = () => {
    const navigate = useNavigate();
    return(
        <div className={"codeSentSuccess"}>
            <div className={"mx-auto text-center"}>
                <div className={"m-b-20"}>
                    <h1 className={"error404Text"}>404</h1>
                </div>
                <h2>Feeling Lost?</h2>
                <p>Let us help you go back!!</p>
            </div>
            <div className={"mx-auto text-center m-t-40"}>
                <p className={"p-small"}><span onClick={()=>{navigate(-1)}}>Go Back</span></p>
            </div>
        </div>
    )
}



const renderComponent = (type) => {
    console.log(type);
    if(type === "404"){
        return <Error404 />
    }
}

function Error({type}) {

    return (
        <div className={"viewportFull"}>
            <div className={"d-flex align-items-center justify-content-center h-100"}>
                <div className={"componentContainer"}>
                    <div className={"successPage"}>
                        {
                            renderComponent(type)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Error;
