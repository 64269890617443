import React, {useRef, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import {Form, InputGroup, Button} from "react-bootstrap"
import {AiFillEyeInvisible, AiFillEye} from "react-icons/ai"
import {FcGoogle} from "react-icons/fc"
import {FaFacebook, FaTwitter, FaApple} from "react-icons/fa"
import {useForm} from "react-hook-form";
import {Patterns, VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";
import {SuccessNotificationIcon} from "../components/SvgComponents";
import {toast} from "react-toastify";

function CreateAccount() {
    const navigate = useNavigate();
    const [password, setPassword] = useState(false);
    const [cPassword, setCpassword] = useState(false);

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const onSubmit = (data) => {
        console.log(data);
        toast.success("Account created successfully", {
            icon: <SuccessNotificationIcon />,
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setTimeout(() => {
            navigate("/account-verification-professional");
        }, 5000);

    }

    const passwordWatch = useRef({});
    passwordWatch.current = watch("password", "");






    const RenderLabel = () => {
        return (
         <>
             I accept the <Link to={"/terms-of-service"}>Terms of service</Link> and the<br/> <Link to={"/privacy-policy"}>Privacy policy</Link>
         </>
        )
    }
    return (
        <div className={"loginForm "}>
            <h1>Create a professional<br/> account</h1>
            <p>Quam pulvinar nullam quam nunc.</p>
            <div className={"filler m-t-30"}></div>
            <form className={"m-t-40"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                <h3>Account Info</h3>
                <div className={"mb13"}>
                    <Form.Control type="text" autoComplete={"off"} placeholder="First Name" className={"formNameF"}
                                  {...register("fName",
                                      {
                                          maxLength: {
                                              value: VALIDATIONS.NAME,
                                              message: VALIDATIONS_TEXT.NAME_MAX
                                          },
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.FNAME_REQUIRED
                                          },
                                      })
                                  }/>
                    {errors.fName &&
                    <span className={"validation-error"} role="alert">{errors.fName.message}</span>}
                </div>
                <div className={"mb13"}>
                    <Form.Control type="text" autoComplete={"off"} placeholder="Last Name" className={"formNameL"}
                                  {...register("lName",
                                      {
                                          maxLength: {
                                              value: VALIDATIONS.NAME,
                                              message: VALIDATIONS_TEXT.NAME_MAX
                                          },
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.LNAME_REQUIRED
                                          },
                                      })
                                  }/>
                    {errors.lName &&
                    <span className={"validation-error"} role="alert">{errors.lName.message}</span>}
                </div>
                <div className={"mb13"}>
                    <Form.Control type="email" autoComplete={"off"} placeholder="Email address" className={"formemail"}
                                  {...register("email",
                                      {
                                          maxLength: {
                                              value: VALIDATIONS.EMAIL,
                                              message: VALIDATIONS_TEXT.EMAIL_MAX
                                          },
                                          pattern: {
                                              value: Patterns.Email,
                                              message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                          },
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                          },
                                      })
                                  }/>
                    {errors.email &&
                    <span className={"validation-error"} role="alert">{errors.email.message}</span>}
                </div>



                <div className={"filler m-t-30"}></div>
                <h3>Password</h3>
                <div className={"mb13"}>
                    <InputGroup className={"passwordGroup"}>
                        <Form.Control
                            type={password ? "text" : "password"} placeholder="Password"
                            aria-label="password"
                            autoComplete={"off"}
                            aria-describedby="inputGroup-sizing-sm"
                            className={"mb-0"}
                            {...register("password",
                                {
                                    minLength: {
                                        value: VALIDATIONS.PASSWORD_MIN,
                                        message: VALIDATIONS_TEXT.PASSWORD_MIN
                                    },
                                    maxLength: {
                                        value: VALIDATIONS.PASSWORD_MAX,
                                        message: VALIDATIONS_TEXT.PASSWORD_MAX
                                    },
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                    },
                                })
                            }
                        />
                        <Button variant="outline-secondary" id="button-addon1" onClick={()=>setPassword(!password)}>
                            {password ? <AiFillEyeInvisible /> : <AiFillEye/>}
                        </Button>
                    </InputGroup>
                    {errors.password &&
                    <span className={"validation-error"} role="alert">{errors.password.message}</span>}
                </div>

                <div className={"mb13"}>
                    <InputGroup className={"passwordGroup"}>
                        <Form.Control
                            type={cPassword ? "text" : "password"} placeholder="Repeat password"
                            aria-label="password"
                            autoComplete={"off"}
                            aria-describedby="inputGroup-sizing-sm"
                            className={"mb-0"}
                            {...register('Cpassword', {
                                required: {
                                    value: "required",
                                    message: "Confirm password is required"
                                },
                                validate: value => value === passwordWatch.current || "The passwords do not match",
                                minLength: {
                                    value: 8,
                                    message: "Min length is 8"
                                },
                                maxLength: {
                                    value: 255,
                                    message: "Max length is 255"
                                },
                            })}
                        />
                        <Button variant="outline-secondary" id="button-addon2" onClick={()=>setCpassword(!cPassword)}>
                            {cPassword ? <AiFillEyeInvisible /> : <AiFillEye/>}
                        </Button>
                    </InputGroup>
                    {errors.Cpassword &&
                    <span className={"validation-error"} role="alert">{errors.Cpassword.message}</span>}
                </div>


                <ul className={"list-login list-unstyled"}>
                    <li className={"list-item "}>
                        <Form.Check
                            type={"checkbox"}
                            id={"consentPolicy"}
                            label={<RenderLabel />}
                            className={"formCheckbox"}
                            {...register("consentPolicy",
                                {
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.POLICY_CONSENT
                                    },
                                })
                            }
                        />
                        {errors.consentPolicy &&
                        <span className={"validation-error"} role="alert">{errors.consentPolicy.message}</span>}
                    </li>
                    <li className={"list-item m-t-20"}>
                        <Form.Check
                            type={"checkbox"}
                            id={"consentAge"}
                            label={"I confirm I’m over 13 years old"}
                            className={"formCheckbox"}
                            {...register("consentAge",
                                {
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.AGE_CONSENT
                                    },
                                })
                            }
                        />
                        {errors.consentAge &&
                        <span className={"validation-error"} role="alert">{errors.consentAge.message}</span>}
                    </li>
                </ul>

                <Button type={"submit"} className={"w-100 btn-solid"}>
                    Create account
                </Button>
            </form>
            <div className={"filler m-t-30"}></div>
            <p className={"text-center p-account"}>Already have an account? <Link to={"/"} className={"linkNav"}>Sign in</Link></p>
            <div className={"filler m-b-30"}></div>
        </div>
    );
}

export default CreateAccount;
