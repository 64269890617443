import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import {HiArrowSmLeft} from 'react-icons/hi';
import {LeftIcon, SuccessNotificationIcon} from "../components/SvgComponents";
import {useForm} from "react-hook-form";
import {Patterns, VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";
import {toast} from "react-toastify";

function ForgotPassword() {
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const onSubmit = (data) => {
        console.log(data);
        toast.success("Verification Code sent to email", {
            icon: <SuccessNotificationIcon />,
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setTimeout(() => {
            navigate("/success");
        }, 5000);

    }
  return (
      <div className={"viewportFull"}>
          <div className={"d-flex align-items-center justify-content-center h-100"}>
              <div className={"componentContainer"}>
                  <div className={"forgotPassword"}>
                      <h1>Password recovery</h1>
                      <p>Curabitur id ex et ex pharetra mollis. Duis<br/> placerat, augue vel accumsan feugiat.</p>

                      <Form className={"m-t-40"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                          <div className={"mb13"}>
                              <Form.Control type="email" autoComplete={"off"} placeholder="username or email" className={"formEmail"}
                                            {...register("email",
                                                {
                                                    maxLength: {
                                                        value: VALIDATIONS.EMAIL,
                                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                                    },
                                                    pattern: {
                                                        value: Patterns.Email,
                                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                    },
                                                })
                                            }/>
                              {errors.email &&
                              <span className={"validation-error"} role="alert">{errors.email.message}</span>}
                          </div>

                          <Button type={"submit"} className={"w-100 btn-solid m-t-30"}>
                              Send instructions
                          </Button>
                      </Form>

                      <div className={"mx-auto text-center m-t-40"}>
                          <Link to={"/"} className={"backToBtn"}>
                              <LeftIcon /> Back to login
                          </Link>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default ForgotPassword;
