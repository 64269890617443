import React from "react";
import {Outlet} from "react-router-dom";
import ProfessionalNavigation from "../components/navigations/ProfessionalNavigation";

function DashboardLayout() {
  return (
      <div className={"dashboardLayout"}>
          <ProfessionalNavigation />
          <Outlet />
      </div>
  );
}

export default DashboardLayout;
