import React from "react";
import {Col, Container, Row, Button, FormLabel, Form} from "react-bootstrap";
import {ArrowIcon, ChevronRight, Star} from "../components/SvgComponents";
import AccSidebar from "../components/AccSidebar";
import { ToastContainer, toast } from 'react-toastify';



function Settings() {
    return (
        <div className={"mainDashboard accountSettings"}>
            <div className={"ProfileInformation"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} className={"col-xxl-4 d-none d-md-block"}>
                                    <AccSidebar />
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={8} xl={{span: 7, offset: 1}} className={"col-xxl-7 offset-xxl-1"}>
                                    <h1 className={"m-b-30"}>Settings</h1>

                                    <div className={"checkBox-container m-b-20"}>
                                        <div className={"checkBox-Group"}>
                                            <FormLabel className={""} htmlFor={"showName"}>
                                                Show name
                                            </FormLabel>
                                            <Form.Check
                                                type="switch"
                                                id="showName"
                                            />
                                        </div>
                                        <p>Curabitur id ex et ex pharetra mollis. Duis placerat, augue.</p>
                                    </div>
                                    <hr/>
                                    <div className={"checkBox-container m-b-20"}>
                                        <div className={"checkBox-Group"}>
                                            <FormLabel className={""} htmlFor={"showLocation"}>
                                                Show location
                                            </FormLabel>
                                            <Form.Check
                                                type="switch"
                                                id="showLocation"
                                            />
                                        </div>
                                        <p>Curabitur id ex et ex pharetra mollis. Duis placerat, augue.</p>
                                    </div>
                                    <hr/>
                                    <div className={"checkBox-container m-b-20"}>
                                        <div className={"checkBox-Group"}>
                                            <FormLabel className={""} htmlFor={"showBadge"}>
                                                Show badge
                                            </FormLabel>
                                            <Form.Check
                                                type="switch"
                                                id="showBadge"
                                            />
                                        </div>
                                        <p>Curabitur id ex et ex pharetra mollis. Duis placerat, augue.</p>
                                    </div>
                                    <hr/>
                                    <div className={"checkBox-container m-b-20"}>
                                        <div className={"checkBox-Group"}>
                                            <FormLabel className={""}>
                                                Blocked Users
                                            </FormLabel>
                                        </div>
                                        <p>Curabitur id ex et ex pharetra mollis. Duis placerat, augue.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <ToastContainer />
                </Container>
            </div>
        </div>
    );
}

export default Settings;
