import React, {useRef, useState} from "react";
import {Col, Container, Row, Button, Form, FloatingLabel, InputGroup} from "react-bootstrap";
import profileImg from "../../assets/images/others/profileUser.png";
import {Link, NavLink} from "react-router-dom";
import {ArrowIcon, SuccessNotificationIcon} from "../components/SvgComponents";
import AccSidebar from "../components/AccSidebar";
import {useForm} from "react-hook-form";
import {Patterns, VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";
import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';



function SecurityInfo() {
    const [oldPassword, setOldPassword] = useState(false);
    const [password, setPassword] = useState(false);
    const [cPassword, setCpassword] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const passwordWatch = useRef({});
    passwordWatch.current = watch("password", "");

    const onSubmit = (data) => {
        console.log(data);
        toast.success("Password changed successfully", {
            icon: <SuccessNotificationIcon />,
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    }

    return (
        <div className={"mainDashboard accountSettings"}>
            <div className={"ProfileInformation"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} className={"col-xxl-4 d-none d-md-block"}>

                                    <AccSidebar />
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={8} xl={{span: 7, offset: 1}} className={"col-xxl-7 offset-xxl-1"}>
                                    <h1>Security</h1>
                                    <form className={"profileInfoForm"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                                        <h4>Change Password</h4>
                                        <div className={"mb13"}>
                                            <InputGroup className={"passwordGroup"}>
                                                <Form.Control
                                                    type={oldPassword ? "text" : "password"} placeholder="Password"
                                                    aria-label="Current password"
                                                    autoComplete={"off"}
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    className={"mb-0"}
                                                    {...register("oldPassword",
                                                        {
                                                            minLength: {
                                                                value: VALIDATIONS.PASSWORD_MIN,
                                                                message: VALIDATIONS_TEXT.PASSWORD_MIN
                                                            },
                                                            maxLength: {
                                                                value: VALIDATIONS.PASSWORD_MAX,
                                                                message: VALIDATIONS_TEXT.PASSWORD_MAX
                                                            },
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                                            },
                                                        })
                                                    }
                                                />
                                                <Button variant="outline-secondary" id="button-addon1" onClick={()=>setOldPassword(!oldPassword)}>
                                                    {oldPassword ? <AiFillEyeInvisible /> : <AiFillEye/>}
                                                </Button>
                                            </InputGroup>
                                            {errors.oldPassword &&
                                            <span className={"validation-error"} role="alert">{errors.oldPassword.message}</span>}
                                        </div>
                                        <div className={"mb13"}>
                                            <InputGroup className={"passwordGroup"}>
                                                <Form.Control
                                                    type={password ? "text" : "password"} placeholder="Password"
                                                    aria-label="password"
                                                    autoComplete={"off"}
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    className={"mb-0"}
                                                    {...register("password",
                                                        {
                                                            minLength: {
                                                                value: VALIDATIONS.PASSWORD_MIN,
                                                                message: VALIDATIONS_TEXT.PASSWORD_MIN
                                                            },
                                                            maxLength: {
                                                                value: VALIDATIONS.PASSWORD_MAX,
                                                                message: VALIDATIONS_TEXT.PASSWORD_MAX
                                                            },
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                                            },
                                                        })
                                                    }
                                                />
                                                <Button variant="outline-secondary" id="button-addon1" onClick={()=>setPassword(!password)}>
                                                    {password ? <AiFillEyeInvisible /> : <AiFillEye/>}
                                                </Button>
                                            </InputGroup>
                                            {errors.password &&
                                            <span className={"validation-error"} role="alert">{errors.password.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <InputGroup className={"passwordGroup"}>
                                                <Form.Control
                                                    type={cPassword ? "text" : "password"} placeholder="Repeat password"
                                                    aria-label="password"
                                                    autoComplete={"off"}
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    className={"mb-0"}
                                                    {...register('Cpassword', {
                                                        required: {
                                                            value: "required",
                                                            message: "Confirm password is required"
                                                        },
                                                        validate: value => value === passwordWatch.current || "The passwords do not match",
                                                        minLength: {
                                                            value: 8,
                                                            message: "Min length is 8"
                                                        },
                                                        maxLength: {
                                                            value: 255,
                                                            message: "Max length is 255"
                                                        },
                                                    })}
                                                />
                                                <Button variant="outline-secondary" id="button-addon2" onClick={()=>setCpassword(!cPassword)}>
                                                    {cPassword ? <AiFillEyeInvisible /> : <AiFillEye/>}
                                                </Button>
                                            </InputGroup>
                                            {errors.Cpassword &&
                                            <span className={"validation-error"} role="alert">{errors.Cpassword.message}</span>}
                                        </div>

                                        <Button type={"submit"} className={"btn-solid w-100 m-t-30"}>
                                            Save
                                        </Button>
                                    </form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <ToastContainer />
                </Container>
            </div>
        </div>
    );
}

export default SecurityInfo;
